/* eslint-disable */
import * as React from "react";
import "./index.css";
import { widget } from "../../charting_library";
import configureSymbol from "../../chartUtils";

function getUrl() {
  const env_var =
    typeof window._env_ !== "undefined" ? window._env_ : process.env;
  return env_var.REACT_APP_CHART_URL;
}

function getLanguageFromURL() {
  const regex = new RegExp("[\\?&]lang=([^&#]*)");
  const results = regex.exec(window.location.search);
  return results === null
    ? null
    : decodeURIComponent(results[1].replace(/\+/g, " "));
}

class TVChartContainer extends React.PureComponent {
  static defaultProps = {
    symbol: "NEPSE",
    interval: "D",
    containerId: "tv_chart_container",
    datafeedUrl: getUrl() + "/tv",
    libraryPath: "/charting_library/",
    chartsStorageUrl: getUrl() + "/mobileChart/saveChart",
    chartsStorageApiVersion: "1.1",
    clientId: "systemxMobile.tradingview.com",
    userId: localStorage.getItem("uid"),
    fullscreen: true,
    autosize: true,
    studiesOverrides: {},
    timezone: "Asia/Kathmandu",
    width: 500,
    height: 500,
  };
  tvWidget = null;

  componentDidMount() {
    const widgetOptions = {
      symbol: localStorage.getItem("symbol") || "NEPSE",
      // BEWARE: no trailing slash is expected in feed URL
      datafeed: new window.Datafeeds.UDFCompatibleDatafeed(
        this.props.datafeedUrl
      ),
      interval: this.props.interval,
      container: this.props.containerId,
      library_path: this.props.libraryPath,

      symbol_search_request_delay: 1000,
      locale: getLanguageFromURL() || "en",
      enabled_features: ["use_localstorage_for_settings", "study_templates"],
      // disabled_features: ['study_templates'],
      charts_storage_url: this.props.chartsStorageUrl,
      charts_storage_api_version: this.props.chartsStorageApiVersion,
      client_id: this.props.clientId,
      user_id: this.props.userId,
      save_chart_properties_to_local_storage: true,
      studies_overrides: this.props.studiesOverrides,
      timezone: this.props.timezone,
      width: this.props.width,
      height: this.props.height,
      theme: "Light",
      autosize: true,
      fullscreen: true,
      // theme: this.props.theme
      overrides: {
        timezone: "Asia/Kathmandu",
      },
    };

    const tvWidget = new widget(widgetOptions);
    this.tvWidget = tvWidget;

    tvWidget.onChartReady(() => {
      tvWidget.headerReady().then(() => {
        // tvWidget.changeTheme(this.props.theme);
        // document.querySelector("#loadChart").addEventListener("click", () => {
        //     tvWidget.activeChart().clearMarks();
        //     tvWidget.activeChart().refreshMarks();
        // })

        const button = tvWidget.createButton();
        button.setAttribute("title", "Click to change the chart type");
        button.classList.add("apply-common-tooltip");

        button.addEventListener("click", () => {
          let currentSymbol = tvWidget.activeChart().symbol().toLowerCase();
          currentSymbol = configureSymbol(currentSymbol);
          // console.log('Button Clicked' + currentSymbol)
          if (currentSymbol.includes("_adj")) {
            const stockSymbol = currentSymbol.split("_")[0];
            tvWidget.activeChart().setSymbol(stockSymbol);
            button.innerHTML = "Adjusted";
          } else {
            tvWidget.activeChart().setSymbol(currentSymbol + "_adj");
            button.innerHTML = "Unadjusted";
          }
        });
        button.innerHTML = "Unadjusted";
      });

      tvWidget
        .activeChart()
        .onDataLoaded()
        .subscribe(
          null,
          () => {
            console.log("New history bars are loaded");
          },
          true
        );
    });
  }

  componentWillUnmount() {
    if (this.tvWidget !== null) {
      this.tvWidget.remove();
      this.tvWidget = null;
    }
  }

  render() {
    return <div id={this.props.containerId} className={"TVChartContainer"} />;
  }
}

export default TVChartContainer;
